import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import FooterCrown from "../components/template-partials/footer-crown"

const AccessibilityPage = () => {
  return (
    <Layout route="four-oh-four">
      <Seo title="Terms and Conditions" />

      <div className="row lg_mt-3 mt-2">
        <div className="columns small-12 large-9">
          <h1 className="color--purple text--watch-quinn">
            Accessibility statement for SUSPECTSM.com
          </h1>
        </div>
      </div>

      <div className="row lg_mt-2 mt-2 lg_mb-7 mb-7">
        <div className="columns">
          <p className="color--purple h5">
            Blueprint Medicines is committed to ensuring digital accessibility
            for people with disabilities. We are continually improving the user
            experience for everyone and applying the relevant accessibility
            standards.
          </p>

          <p className="color--purple h5 lg_mt-2 mt-2">
            <strong>Conformance status</strong>
          </p>

          <p className="color--purple h5 lg_mt-2 mt-2">
            The Web Content Accessibility Guidelines (WCAG) define requirements
            for designers and developers to improve accessibility for people
            with disabilities. It defines three levels of conformance: Level A,
            Level AA, and Level AAA. SUSPECTSM.com is partially conformant with
            WCAG 2.0 Level AA. Partially conformant means that some parts of the
            content do not fully conform to the accessibility standard.
          </p>
        </div>
      </div>

      <FooterCrown type="alt1" />
    </Layout>
  )
}

export default AccessibilityPage
